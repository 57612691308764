import './highlights.scss';

interface Highlight {
    src: string;
    text: string;
    subtext?: string;
}

interface HighlightsProps {
    highlights: Highlight[];
    elementsPerLine: number;
}

const Highlights: React.FC<HighlightsProps> = ({ highlights, elementsPerLine }) => {
    return (
        <div className="highlight-wrapper">
            {highlights.map((highlight, index) => (
                <div className='highlight' style={{ width: `${100 / elementsPerLine}%` }} key={index}>
                    <div className='img-wrapper'>
                        <img src={highlight.src} alt='highlight' />
                        </div>
                    <h4>{highlight.text}</h4>
                    {highlight.subtext && <p className='emerald'>{highlight.subtext}</p>}
                </div>
            ))}
        </div>
    );
};

export default Highlights;
