import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useLayoutEffect, useState } from "react";
import LocationSpec from "../../components/locationSpec/locationSpec";
import { amenitiesSlides, iconsList, locationSpec } from "../../utils/where";
import Icons from "../../components/icons/icons";
import Gallery from "../../components/swiper/swiper";
import { useLocation } from "react-router-dom";
import "./where.scss";
import Footer from "../../components/footer/footer";

const Where = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
    const location = useLocation();

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1024);
        };

        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <motion.div className='where pad'>
            <motion.div
                initial={!isMobile && { width: 45, zIndex: 2 }}
                animate={!isMobile && { width: "100%", zIndex: 2 }}
                className='hero'
            >
                <motion.div
                    initial={!isMobile && { opacity: 0 }}
                    animate={!isMobile && { opacity: 1 }}
                    transition={{ duration: 0.5, delay: 1 }}
                    className='title'
                >
                    <h1>WHERE</h1>
                    <img src='../images/arrows.svg' />
                </motion.div>
                <div className='splash brown'>                    
                    <img
                        src='../images/arrow/hero-arrow.svg'
                        className='hero-arrow'
                    />
                    <motion.div
                        animate={!isMobile && { x: "0", opacity: 1 }}
                        initial={!isMobile && { x: -50, opacity: 0 }}
                        transition={{ duration: 0.5, delay: 0.75 }}
                        className='intro-text'
                    >
                        <h2>
                            <b>
                                Join 13 of the 2024 Fortune 500 companies in the
                                immediate vicinity and the surrounding area, 5 of
                                these are ranked on the fortune 50 list.
                            </b>
                        </h2>
                    </motion.div>
                    {isMobile ? (
                        <img src={`../images/where/mb-1.png`} className="hero-image" alt="hero-image" />
                        ) : 
                    <motion.div
                    animate={!isMobile && { x: "0", opacity: 1 }}
                    initial={!isMobile && { x: 50, opacity: 0 }}
                    transition={{ duration: 0.5, delay: 0.75 }}
                    style={{backgroundImage: `url(../images/where/1.png)`}}
                    className='hero-image'
                    >
                       
                        
                </motion.div>  }
                </div>
            </motion.div>
            <div className='contain-wrapper'>
                <img
                    src='../images/where/backs/mask2.png'
                    alt='back'
                    className='amen-back desktop'
                />
                <div className='amenities contain'>
                    <h3 className='underline emerald'>SANDYFORD, DUBLIN</h3>
                    <div className='flex-box'>
                        <div>
                            <h6 className='emerald'>
                                DUBLIN’S LEADING SUBURBAN OFFICE LOCATION
                            </h6>
                            <p className='emerald'>
                                18 mins from Dublin city centre
                            </p>
                        </div>
                        <div>
                            <h6 className='emerald'>
                                EXCELLENT PUBLIC TRANSPORT LINKS
                            </h6>
                            <p className='emerald'>LUAS, Bus and M50 network</p>
                        </div>
                        <div>
                            <h6 className='emerald'>
                                KEY OCCUPIERS IN THE VICINITY
                            </h6>
                            <p className='emerald'>
                                Microsoft, Facebook, Google, Salesforce,
                                Mastercard, Vodafone
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='contain-wrapper'>
                {!isMobile ? (
                    <div className='amenities contain'>
                        <h3 className='underline emerald'>AMENITIES</h3>
                        <div className='gallery'>
                            <img
                                src='../images/where/gallery/group.jpg'
                                width={"100%"}
                            />
                        </div>
                    </div>
                ) : (
                    <div className='mb-amenities contain'>
                        <h3 className='underline emerald'>AMENITIES</h3>
                        <Gallery
                            slides={amenitiesSlides}
                            classNames={"whatpics"}
                        />{" "}
                    </div>
                )}
            </div>
            <div className='contain-wrapper'>
                <img
                    src={`../images/where/2.${isMobile ? "jpg" : "png"}`}
                    alt='hero'
                    className='contain'
                />
            </div>
            {isMobile && (
                <div className='map-locations'>
                    <div className='flex'>
                        <p>01</p>
                        <p>Microsoft</p>
                    </div>
                    <div className='flex'>
                        <p>02</p>
                        <p>Mastercard</p>
                    </div>
                    <div className='flex'>
                        <p>03</p>
                        <p>Vodafone</p>
                    </div>
                    <div className='flex'>
                        <p>04</p>
                        <p>aib</p>
                    </div>
                    <div className='flex'>
                        <p>05</p>
                        <p>google</p>
                    </div>
                    <div className='flex'>
                        <p>06</p>
                        <p>merrill lynch</p>
                    </div>
                    <div className='flex'>
                        <p>07</p>
                        <p>leaspan</p>
                    </div>
                    <div className='flex'>
                        <p>08</p>
                        <p>clayton hotel</p>
                    </div>
                    <div className='flex'>
                        <p>09</p>
                        <p>chill insurance</p>
                    </div>
                    <div className='flex'>
                        <p>10</p>
                        <p>meta</p>
                    </div>
                    <div className='flex'>
                        <p>11</p>
                        <p>bnp paribas</p>
                    </div>
                    <div className='flex'>
                        <p>12</p>
                        <p>Salesforce</p>
                    </div>
                    <div className='flex'>
                        <p>13</p>
                        <p>verizon</p>
                    </div>
                    <div className='flex'>
                        <p>14</p>
                        <p>google</p>
                    </div>
                </div>
            )}
            <div className='contain-wrapper'>
                <div className='text-wrapper contain'>
                    <h4 className='emerald-copy'>
                        Sandyford is the most connected suburban office location
                        in Dublin and is served by all major transport links.
                    </h4>
                </div>
            </div>
            <div className='contain-wrapper'>
                <div className='contain'>
                    <LocationSpec location={locationSpec} />
                </div>
            </div>
            <div className='contain-wrapper diagonal'>
                <div className='c contain'>
                    <div className='map-section'>
                       

                        <img src='../images/where/3.jpg' />
                       
                        <div>
                            <Icons icons={iconsList} />
                        </div>
                    </div>
                </div>
            </div>
            {location.pathname !== "/" && <Footer />}
        </motion.div>
    );
};

export default Where;
