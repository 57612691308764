import { motion } from "framer-motion";
import { useEffect, useLayoutEffect, useState } from "react";
import { handleAnimationComplete } from "../../utils/animation";
import GoogleMapReact from "google-map-react";
import "./who.scss";
import { useLocation } from "react-router-dom";
import Footer from "../../components/footer/footer";
interface Props {
    text: string;
}
const imageSize = 100;
const anchorX = imageSize / 2; // Middle horizontally
const anchorY = imageSize; // Bottom vertically
const AnyReactComponent: React.FC<Props> = ({ text }) => (
    
    <div className="custom-marker">
    <img src={text} alt="marker" />
  </div>
);


const mapProps = {
    center: { lat: 53.27663275484625, lng: -6.209125080417236 },
    zoom: 14,
    
};
const mapOptions = {
    styles: [
        {
            "featureType": "all",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#7c93a3"
                },
                {
                    "lightness": "-10"
                }
            ]
        },
        {
            "featureType": "administrative.country",
            "elementType": "geometry",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "administrative.country",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#a0a4a5"
                }
            ]
        },
        {
            "featureType": "administrative.province",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#62838e"
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#dde3e3"
                }
            ]
        },
        {
            "featureType": "landscape.man_made",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#3f4a51"
                },
                {
                    "weight": "0.30"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "simplified"
                }
            ]
        },
        {
            "featureType": "poi.attraction",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "poi.business",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "poi.government",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "poi.place_of_worship",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "poi.school",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "poi.sports_complex",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "all",
            "stylers": [
                {
                    "saturation": "-100"
                },
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#bbcacf"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "lightness": "0"
                },
                {
                    "color": "#bbcacf"
                },
                {
                    "weight": "0.50"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "labels",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "labels.text",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "road.highway.controlled_access",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "road.highway.controlled_access",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#a9b4b8"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "invert_lightness": true
                },
                {
                    "saturation": "-7"
                },
                {
                    "lightness": "3"
                },
                {
                    "gamma": "1.80"
                },
                {
                    "weight": "0.01"
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#a3c7df"
                }
            ]
        }
    ]
};
const Who = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
    const location = useLocation();

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 789);
        };

        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <motion.div className='who pad'>
            <motion.div
                initial={!isMobile && { width: 45, zIndex: 2 }}
                animate={!isMobile && { width: "100%", zIndex: 2 }}
                transition={{ duration: 0.2, delay: 0.5 }}
                onAnimationComplete={handleAnimationComplete}
                className='hero'
            >
                <motion.div
                    initial={!isMobile && { opacity: 0 }}
                    animate={!isMobile && { opacity: 1 }}
                    transition={{ duration: 0.5, delay: 1 }}
                    className='title'
                >
                    <h1>WHO</h1>
                    <img src='../images/arrows.svg' alt='hero' />
                </motion.div>
                <div className='splash yellow'>
                    <motion.div
                        animate={!isMobile && { x: "0", opacity: 1 }}
                        initial={!isMobile && { x: -50, opacity: 0 }}
                        transition={{ duration: 0.5, delay: 0.75 }}
                        className='contacts'
                    >
                        <h2 className='emerald'>
                            <b>
                                Our team has a track record of delivering for
                                successful businesses.
                            </b>
                        </h2>
                        <div className='flex-wrapper'>
                            <div className='flex-box'>
                                <div className='img-wrapper'>
                                    <img
                                        src='../images/who/cbre.svg'
                                        alt='hero'
                                    />
                                </div>
                                <div className='agent'>
                                    <p>Sam Daunt</p>
                                    <p>+353 (0)1 6185 500</p>
                                    <a href='mailto:sam.daunt@cbre.com'>
                                        <p>sam.daunt@cbre.com</p>
                                    </a>
                                </div>
                                <div className='agent'>
                                    <p>Luke Taheny</p>
                                    <p>+353 (0)1 6185 500</p>
                                    <a href='mailto:luke.taheny@cbre.com'>
                                        <p>luke.taheny@cbre.com</p>
                                    </a>
                                </div>
                            </div>
                            <div className='flex-box'>
                                <div className='img-wrapper'>
                                    <img
                                        src='../images/who/lisney.svg'
                                        alt='hero'
                                    />
                                </div>
                                <div className='agent'>
                                    <p>Conor Lennon</p>
                                    <p>+353 (0)1 6382 700</p>
                                    <a href='mailto:clennon@lisney.com'>
                                        <p>clennon@lisney.com</p>
                                    </a>
                                </div>
                                <div className='agent'>
                                    <p>Paul Hipwell</p>
                                    <p>+353 (0)1 6382 732</p>
                                    <a href='mailto:phipwell@lisney.com'>
                                        <p>phipwell@lisney.com</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </div>   
                <motion.div
                    className='map'
                    animate={!isMobile && { x: "0", opacity: 1 }}
                    initial={!isMobile && { x: 50, opacity: 0 }}
                    transition={{ duration: 0.5, delay: 0.75 }}
                >
                    <div className='map-tri'></div>
                    <GoogleMapReact
                        bootstrapURLKeys={{
                            key: "AIzaSyBs9fOEZFVmtgCQT32tig-RtnryKmiWyjk",
                        }}
                        defaultCenter={mapProps.center}
                        defaultZoom={mapProps.zoom}
                        options={mapOptions}
                    >
                        <AnyReactComponent
                            // @ts-ignore
                            lat={53.2766327548462}
                            lng={-6.209125080417236}
                            text={"../images/who/marker.svg"}
                        />
                    </GoogleMapReact>
                </motion.div>
            </motion.div>
            <div className='mobile agents'>
                <img src='../images/who/cbre.svg' alt='cbre' />
                <div className='flex-box'>
                    <div className='agent'>
                        <p>Sam Daunt</p>
                        <p>+353 (0)1 6185 500</p>
                        <a href='mailto:sam.daunt@cbre.com'>
                            <p>sam.daunt@cbre.com</p>
                        </a>
                    </div>
                    <div className='agent'>
                        <p>Luke Taheny</p>
                        <p>+353 (0)1 6185 500</p>
                        <a href='mailto:luke.taheny@cbre.com'>
                            <p>luke.taheny@cbre.com</p>
                        </a>
                    </div>
                </div>
                <img src='../images/who/lisney.svg' alt='cbre' />
                <div className='flex-box'>
                    <div className='agent'>
                        <p>Conor Lennon</p>
                        <p>+353 (0)1 6382 700</p>
                        <a href='mailto:clennon@lisney.com'>
                            <p>clennon@lisney.com</p>
                        </a>
                    </div>
                    <div className='agent'>
                        <p>Paul Hipwell</p>
                        <p>+353 (0)1 6382 732</p>
                        <a href='mailto:phipwell@lisney.com'>
                            <p>phipwell@lisney.com</p>
                        </a>
                    </div>
                </div>
            </div>
            <div className='misrep-wrapper'>
                <div className='misrep'>
                    <img src='../images/mptree.svg' />
                    <div>
                        <p>
                            Headquartered in Singapore, Mapletree is a global
                            real estate development, investment, capital and
                            property management company committed to
                            sustainability. Its strategic focus is to invest in
                            markets and real estate sectors with good growth
                            potential. By combining its key strengths, the Group
                            has established a track record of award-winning
                            projects, and delivers consistent and high returns
                            across real estate asset classes.
                            <br></br>
                            <br></br>
                            The Group manages three Singapore-listed real estate
                            investment trusts (“REITs”) and eight private equity
                            real estate funds, which hold a diverse portfolio of
                            assets in Asia Pacific, Europe, the United Kingdom
                            (“UK”) and the United States (“US”). As at 31 March
                            2023, Mapletree owns and manages S$77.4 billion of
                            office, retail, logistics, industrial, data centre,
                            residential and student accommodation properties.
                            <br></br>
                            <br></br>
                            The Group’s assets are located across 13 markets
                            globally, namely Singapore, Australia, Canada,
                            China, Europe, Hong Kong SAR, India, Japan,
                            Malaysia, South Korea, the UK, the US and Vietnam.
                            To support its global operations, Mapletree has
                            established an extensive network of offices in these
                            countries. For more information, please visit
                            www.mapletree.com.sg.
                        </p>
                    </div>
                    <hr></hr>
                    <div>
                        <p>
                            Disclaimer: CBRE U.C [CBRE IRELAND]
                            <br></br>
                            <br></br>
                            These particulars are issued by CBRE Ireland &
                            Lisney, registered in Ireland, no. 316570. Target
                            BER: TBC. PSRA License No. 001528 on the
                            understanding that any negotiations relating to the
                            property are conducted through them.
                            <br></br>
                            <br></br>
                            While every care has been taken in preparing them,
                            CBRE U.C., for themselves and for the vendor/lessor
                            whose agents they are, give notice that: – (i) The
                            particulars are set out as a general outline for
                            guiding potential purchasers/tenants and do not
                            constitute any part of an offer or contract. (ii)
                            Any representation including descriptions,
                            dimensions, references to condition, permissions or
                            licenses for uses or occupation, access and any
                            other details are given in good faith and are
                            believed to be correct, but any intending purchaser
                            or tenant should not rely on them as statements or
                            representations of fact but must satisfy themselves
                            (at their own expense) as to their correctness.
                            (iii) Neither CBRE U.C., nor any of their employees
                            have any authority to make any or give any
                            representation or warranty in relation to the
                            property. March 2024
                            <br></br>
                            <br></br>
                            Registered Office Connaught House, Number One
                            Burlington Road, Dublin 4. PSRA Licence No: 001528.
                        </p>
                    </div>
                </div>
            </div>
            {location.pathname !== "/" && <Footer />}
        </motion.div>
    );
};

export default Who;
