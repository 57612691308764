import { Swiper as SwiperComponent, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import './swiper.scss';

interface Slide {
    src: string;
}

interface SwiperProps {
    slides: Slide[];
    classNames: string;
    slidesPerView?: number;
    shadow?: boolean;
    extraClass?: string;
}

const HeroSwiper: React.FC<SwiperProps> = ({ slides, classNames, slidesPerView=1, shadow=true }) => {
    return (
        <>
            <SwiperComponent
                spaceBetween={10}
                className={`${classNames}`}
                pagination={{ clickable: true }}
                modules={[Pagination, Navigation, Autoplay]}
                navigation={true}
                loop={true}
                initialSlide={0}
                centeredSlides={true}
                slidesPerView={1.15}
                effect={"fade"}
                autoplay={{ delay: 5000 }}
                breakpoints={{
                    800: {
                        slidesPerView: slidesPerView,
                        spaceBetween: 50
                    },
                }}
            >
                {slides.map((slide, index) => (
                    <SwiperSlide key={index}>
                        {shadow && <div className='gradient'></div>}
                        <div
                            className="swiper-img-back"
                            style={{ backgroundImage: `url(${slide.src})` }}
                        ></div>
                    </SwiperSlide>
                ))}
                <div className="swiper-pagination"></div>
            </SwiperComponent>
        </>
    );
};

export default HeroSwiper;
