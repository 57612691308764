export const whatSlidesPics = [
    {
        title: "What is a slide?",
        src: "../images/what/gallery/1.jpg",
    },
    {
        title: "What is a slide?",
        src: "../images/what/gallery/2.jpg",
    },
    {
        title: "What is a slide?",
        src: "../images/what/gallery/4.jpg",
    },
    {
        title: "What is a slide?",
        src: "../images/what/gallery/5.jpg",
    },
];

export const highlights = [
    {
        src: "../images/what/highlights/1.svg",
        text: "32 car parking spaces",
        subtext: "RATIO – 1:356 sq ft",
    },
    {
        src: "../images/what/highlights/2.svg",
        text: "tenant portal / events",
    },
    {
        src: "../images/what/highlights/3.svg",
        text: "new a/c system",
    },
    {
        src: "../images/what/highlights/4.svg",
        text: "shower facilities",
    },
    {
        src: "../images/what/highlights/5.svg",
        text: "large atrium & social common",
    },
    {
        src: "../images/what/highlights/6.svg",
        text: "in-house coffee shop",
    },
    {
        src: "../images/what/highlights/7.svg",
        text: "private balconies",
    },
    {
        src: "../images/what/highlights/8.svg",
        text: "ber b1   ",
    },
    {
        src: "../images/what/highlights/9.svg",
        text: "cycle hub",
    },
    {
        src: "../images/what/highlights/10.svg",
        text: "wired score gold",
    },
    {
        src: "../images/what/highlights/11.svg",
        text: "led lighting",
    },
    {
        src: "../images/what/highlights/12.svg",
        text: "excellent transport connections",
    },
];

export const fifthFloor = [
    {
        src: "../images/what/highlights/5thf/1.svg",
        text: "Penthouse office",
    },
    {
        src: "../images/what/highlights/5thf/2.svg",
        text: "light filled space",
    },
    {
        src: "../images/what/highlights/5thf/3.svg",
        text: "private terrace",
    },
    {
        src: "../images/what/highlights/5thf/4.svg",
        text: "impressive atrium",
    },
    {
        src: "../images/what/highlights/5thf/5.svg",
        text: "dedicated entrance from lift lobby",
    },
];

export const whatFloorPics = [
    {
        title: "What is a slide?",
        src: "../images/what/floor_gallery/1.jpg",
    },
    {
        title: "What is a slide?",
        src: "../images/what/floor_gallery/2.jpg",
    },
    {
        title: "What is a slide?",
        src: "../images/what/floor_gallery/3.jpg",
    },
    {
        title: "What is a slide?",
        src: "../images/what/floor_gallery/4.jpg",
    },
    {
        title: "What is a slide?",
        src: "../images/what/floor_gallery/5.jpg",
    },
];
