import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import "./narbar.scss";

const Nav: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);
    const [variants1, setVariants1] = useState({});
    const [variants2, setVariants2] = useState({});
    const [variants3, setVariants3] = useState({});

    const refreshHandler = () => {
        if (window.location.pathname === "/what") {
            setVariants1({
                open: { display : 'None' },
                closed: { display : 'flex' },
            });
            setVariants2({
                open: { left: 90, right: "unset" },
                closed: { left: "unset", right: 45 },
            });
            setVariants3({
                open: { left: 135, right: "unset" },
                closed: { left: "unset", right: 0 },
            });
            setIsOpen(true);
            setIsOpen2(false);
            setIsOpen3(false);
        } else if (window.location.pathname === "/where") {
            setVariants2({
                open: { display : 'None' },
                closed: { display : 'flex' },
            });
            setVariants3({
                open: { left: 135, right: "unset" },
                closed: { left: "unset", right: 0 },
            });
            setIsOpen(false);
            setIsOpen2(true);
            setIsOpen3(false);
        } else if (window.location.pathname === "/who") {
            setVariants2({
                closed: { left: 90, right: "unset" },
                open: { left: "100% - 45", right: "unset" },
            });
            setVariants3({
                open: { display : 'None' },
                closed: { display : 'flex' },
            });
            setIsOpen(false);
            setIsOpen2(false);
            setIsOpen3(true);
        } 
        
    };

    useEffect(() => {
        refreshHandler();
    }, [window.location.pathname]);

    return (
        <>
            <motion.div className='nav home'>
                <Link to='/'>
                    <img src='../images/logo.svg' />
                </Link>
            </motion.div>
            <motion.div
                className='nav what'
                variants={variants1}
                animate={isOpen ? "open" : "closed"}
                onClick={() => refreshHandler()}
                id='1'
                transition={{ duration: 0.1 }}
            >
                <Link to='/what' className='link'>
                    <motion.h5>What</motion.h5>
                    <img src='../images/arrow/hero-arrow.svg'/>
                </Link>
            </motion.div>
            <motion.div
                className='nav where'
                variants={variants2}
                animate={isOpen2 ? "open" : "closed"}
                onClick={() => refreshHandler()}
                id='2'
            >
                <Link to='/where' className='link'>
                    <motion.h5>Where</motion.h5>
                    <img src='../images/arrow/hero-arrow.svg'/>
                </Link>
            </motion.div>
            <motion.div
                className='nav who'
                variants={variants3}
                animate={isOpen3 ? "open" : "closed"}
                onClick={() => refreshHandler()}
                id='3'
            >
                <Link to='/who' className='link'>
                    <motion.h5>Who</motion.h5>
                    <img src='../images/arrow/hero-arrow.svg'/>
                </Link>
            </motion.div>
        </>
    );
};

export default Nav;
