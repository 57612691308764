import { Link } from "react-router-dom";
import { motion, useAnimation } from "framer-motion";
import { useEffect, useState } from "react";

const MobileNav: React.FC = () => {
    const linkVariants = {
        hidden: { y: -50, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            transition: { type: "spring", duration: 0.5 },
        },
    };

    const [isOpen, setIsOpen] = useState(false);
    const controls = useAnimation();
    const [isScrolled, setIsScrolled] = useState(false);

    const toggleLinks = () => {
        setIsOpen(!isOpen);
        controls.start(isOpen ? "hidden" : "visible");
    };

    const variants = {
        clicked: {
            x: [0, 16],
            rotate: [0, 0, 45],
            transition: { duration: 0.2 },
        },
        notClicked: {
            rotate: [0, 0],
            x: [-16, 0],
            transition: { duration: 0.2 },
        },
    };
    const variants2 = {
        clicked: { opacity: 0, transition: { duration: 0.2 } },
        notClicked: { opacity: 1, transition: { duration: 0.2 } },
    };
    const variants3 = {
        clicked: {
            x: [0, -16],
            rotate: [0, 0, -45],
            transition: { duration: 0.2 },
        },
        notClicked: {
            rotate: [0, 0],
            x: [16, 0],
            transition: { duration: 0.2 },
        },
    };

    useEffect(() => {
        const handleScroll = () => {
            setIsOpen(false);
            setIsScrolled(window.scrollY > 0);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div className={`mobile-nav ${isScrolled ? "scrolled" : ""}`}>
            <div className='top-holder'>
                <Link to='/'>
                    <img
                        src={
                            isScrolled
                                ? "../images/small_logo.svg"
                                : "../images/full_logo.svg"
                        }
                        alt='logo'
                    />
                </Link>
                <a className='burger' onClick={toggleLinks}>
                    <motion.div
                        animate={isOpen ? "clicked" : "notClicked"}
                        variants={variants}
                        className='burger-line'
                    ></motion.div>
                    <motion.div
                        animate={isOpen ? "clicked" : "notClicked"}
                        variants={variants2}
                        className='burger-line'
                    ></motion.div>
                    <motion.div
                        animate={isOpen ? "clicked" : "notClicked"}
                        variants={variants3}
                        className='burger-line'
                    ></motion.div>
                </a>
            </div>
            {isOpen && (
                <div className='links'>
                    <motion.div
                        className='link'
                        variants={linkVariants}
                        initial='hidden'
                        transition={{ delay: 0.2 }}
                        animate={isOpen ? "visible" : "hidden"}
                        onClick={() => setIsOpen(false)}
                    >
                        <Link to='/what' className="link-content">
                            <h1>WHAT</h1><img src="../images/arrows.svg" />
                        </Link>
                    </motion.div>

                    <motion.div
                        className='link'
                        variants={linkVariants}
                        initial='hidden'
                        transition={{ delay: 0.1 }}
                        animate={isOpen ? "visible" : "hidden"}
                        onClick={() => setIsOpen(false)}
                    >
                        <Link to='/where' className="link-content">
                            <h1>WHERE</h1><img src="../images/arrows.svg" />
                        </Link>
                    </motion.div>
                    <motion.div
                        className='link'
                        variants={linkVariants}
                        initial='hidden'
                        transition={{ delay: 0 }}
                        animate={isOpen ? "visible" : "hidden"}
                        onClick={() => setIsOpen(false)}
                    >
                        <Link to='/who' className="link-content">
                            <h1>WHO</h1><img src="../images/arrows.svg" />
                        </Link>
                    </motion.div>
                </div>
            )}
        </div>
    );
};

export default MobileNav;
