import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages/home/home";
import "./scss/global.scss";
import Navbar from "./components/navbar/navbar";
import Footer from "./components/footer/footer";
import What from "./pages/what/what";
import Where from "./pages/where/where";
import Who from "./pages/who/who";
import MobileNav from "./components/navbar/mobileNav";
import { useEffect, useState } from "react";
import { AnimatePresence } from "framer-motion";
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'G-NHFSFQ4SKH'
}

TagManager.initialize(tagManagerArgs)


const App: React.FC = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);

    useEffect(() => {
        const handleResize = () => {
        setIsMobile(window.innerWidth <= 1024);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <BrowserRouter>
            <AppContent isMobile={isMobile} />
        </BrowserRouter>
    );
};

const AppContent: React.FC<{ isMobile: boolean }> = ({ isMobile }) => {
    const location = useLocation();
    const routes = [
        { path: "/", component: Home },
        { path: "/what", component: What },
        { path: "/where", component: Where },
        { path: "/who", component: Who },
    ];
    return (
        <>
            {location.pathname !== "/" &&
                (isMobile ? <MobileNav /> : <Navbar />)}
            <AnimatePresence mode="wait">
                <Routes>
                    {routes.map((route, index) => (
                        <Route
                            key={index}
                            path={route.path}
                            element={<route.component />}
                        />
                    ))}
                </Routes>
            </AnimatePresence>
        </>
    );
};

export default App;
