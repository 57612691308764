import { useEffect, useLayoutEffect } from "react";
import LottieAnimation from "react-lottie";
import "../../scss/global.scss";
import "./home.scss";
import animation from "./data.json";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const Home: React.FC = () => {
    const navigate = useNavigate();
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animation,
        renderSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };
    useEffect(() => {
        setTimeout(() => {
            navigate("/what");
        }, 3500);
    }, []);

    return (
        <motion.div
            exit={{ opacity: 0, transition: { duration: 1 } }}
            initial='hidden'
            animate='visible'
            style={{
                height: "100vh",
                width: "140vw",
                marginLeft: "-20vw",
                overflow: "hidden",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                zIndex: -1,
            }}
        >
            <LottieAnimation
                options={defaultOptions}
                height={"auto"}
                width={"100%"}
                style={{
                    overflow: "hidden",
                    position: "relative",
                    zIndex: 1,
                }}
            />
        </motion.div>
    );
};

export default Home;
