import './icons.scss';

interface Icon {
    src: string,
    copy: string
}

interface IconProps {
    icons: Icon[]
}

const Icons: React.FC<IconProps> = ({ icons }) => {
    return (
        <div className="icons">
            {icons.map((icon, index) => (
                <div className="icon" key={index}>
                    <div className='img-wrapper'>
                        <img src={icon.src} alt={`icon${index + 1}`} />
                    </div>
                    <p>{icon.copy}</p>
                </div>
            ))}
        </div>
    )
}

export default Icons;
