import "./locationSpec.scss";

interface Location {
    distance: string;
    title: string;
    copy: string;
}

interface LocationProps {
    location: Location[];
}

const LocationSpec: React.FC<LocationProps> = ({ location }) => {
    return (
        <div className='locationSpec'>
            {location.map((value, index) => (
                <div className='loc' key={index}>
                    <img src='../images/arrow/orange.svg' alt='hero' />
                    <div>
                        <div className='spec-title'>
                            <p className='orange'>{value.distance}</p>
                        </div>
                        <div className='copy'>
                            <h6 className='emerald'>{value.title}</h6>
                            <p className='emerald'>{value.copy}</p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default LocationSpec;
