export const locationSpec = [
    {
        distance: "2 minutes",
        title: "aircoach & bus stop",
        copy: "Connects to Dublin Airport and across Dublin"
    },
    {
        distance: "3 minutes",
        title: "luas stop",
        copy: "Connects throughout Dublin and to all major rail lines"
    },
    {
        distance: "8 minutes",
        title: "dundrum",
        copy: "Large affluent residential catchment & location of Ireland's largest Shopping Centre"
    },
    {
        distance: "18 minutes",
        title: "Dublin city centre",
        copy: "St. Stephens Green"
    },
    {
        distance: "30 minutes",
        title: "Dublin airport",
        copy: "Via M50 by car"
    },
];


export const iconsList = [
    {
        src: "../images/where/icons/1.svg",
        copy: "LUAD Green Line"  
    },
    {
        src: "../images/where/icons/2.svg",
        copy: "LUAD Red Line"  
    },
    {
        src: "../images/where/icons/3.svg",
        copy: "Dublin Airport"  
    },
    {
        src: "../images/where/icons/4.svg",
        copy: "Junction 14 M50"  
    },
    {
        src: "../images/where/icons/5.svg",
        copy: "Bus & Aircoach"  
    },
    {
        src: "../images/where/icons/6.svg",
        copy: "Port Tunnel"  
    },
    {
        src: "../images/where/icons/7.svg",
        copy: "Dart Line"  
    },
]

export const amenitiesSlides = [
    {
        src: "../images/where/gallery/1.jpg",
        copy: "firstImage"
    },
    {
        src: "../images/where/gallery/2.jpg",
        copy: "firstImage"
    },
    {
        src: "../images/where/gallery/3.jpg",
        copy: "firstImage"
    },
    {
        src: "../images/where/gallery/4.jpg",
        copy: "firstImage"
    },
    {
        src: "../images/where/gallery/5.jpg",
        copy: "firstImage"
    },
    {
        src: "../images/where/gallery/6.jpg",
        copy: "firstImage"
    }
] 