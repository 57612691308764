import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import "./footer.scss";

const Footer: React.FC = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };
    handleResize();
  }, []);


  return (
    <>
      <div className="contain-wrapper footer">
        <img src="../images/where/backs/mask.png" alt="mask" className="mask-footer" />

        <div className="contain trans footer">
          <div>
            <h3 className="underline">DOWNLOADS</h3>
          </div>
          <div className="buttons">
            <motion.a className="download-btn" target="_blank" href="../dl/brochure.pdf" whileHover="hover">
              <p>BROCHURE</p>
              <img src={"../images/arrow/arrow2.svg"} alt="arrows" />
            </motion.a>
            {/* <motion.a className="download-btn"  whileHover="hover">
              <p>FLOOR PLAN</p>
              <img src={ "../images/arrow/arrow2.svg"} alt="arrows" />
            </motion.a> */}
            <motion.a href="/who" className="download-btn contact" whileHover="hover">
              <p>CONTACT US</p>
              <img src="../images/arrow/beige.svg" alt="arrows" />
            </motion.a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
